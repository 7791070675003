<template>
    <v-dialog v-model="show" max-width="1000">
        <v-card>
            <v-toolbar flat color="primary" dark>
                <v-icon left>mdi-package-variant-closed</v-icon>
                <v-toolbar-title>Neue Kartonage</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="close">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>

            <v-card-text class="pt-4">
                <v-form ref="form">
                    <v-row>
                        <v-col cols="12" sm="6">
                            <v-text-field
                                v-model="packaging.sku"
                                label="SKU"
                                outlined
                                dense
                                required
                                placeholder="z.B. BOX-001"
                            ></v-text-field>
                        </v-col>

                        <v-col cols="12" sm="6">
                            <v-text-field
                                v-model="packaging.title"
                                label="Bezeichnung"
                                outlined
                                dense
                                required
                                placeholder="z.B. Standard Karton Klein"
                            ></v-text-field>
                        </v-col>

                        <v-col cols="12" sm="4">
                            <v-text-field
                                v-model="packaging.lengthCm"
                                label="Länge (cm)"
                                type="number"
                                outlined
                                dense
                                required
                            ></v-text-field>
                        </v-col>

                        <v-col cols="12" sm="4">
                            <v-text-field
                                v-model="packaging.widthCm"
                                label="Breite (cm)"
                                type="number"
                                outlined
                                dense
                                required
                            ></v-text-field>
                        </v-col>

                        <v-col cols="12" sm="4">
                            <v-text-field
                                v-model="packaging.heightCm"
                                label="Höhe (cm)"
                                type="number"
                                outlined
                                dense
                                required
                            ></v-text-field>
                        </v-col>

                        <v-col cols="12" sm="6">
                            <v-text-field
                                v-model="packaging.weight"
                                label="Gewicht (g)"
                                type="number"
                                outlined
                                dense
                                required
                            ></v-text-field>
                        </v-col>

                        <v-col cols="12" sm="6">
                            <v-text-field
                                v-model="packaging.pricePerUnit"
                                label="Preis pro Stück (€)"
                                type="number"
                                outlined
                                dense
                                required
                                prefix="€"
                            ></v-text-field>
                        </v-col>

                        <v-col cols="12">
                            <!-- Modern Dropzone -->
                            <div 
                                class="dropzone-container"
                                @dragover.prevent
                                @drop.prevent
                            >
                                <input
                                    type="file"
                                    ref="fileInput"
                                    multiple
                                    accept="image/*"
                                    class="file-input"
                                    @change="handleFileSelect"
                                >
                                <div class="dropzone-content">
                                    <v-icon size="48" color="grey lighten-1">mdi-cloud-upload</v-icon>
                                    <div class="text-h6 grey--text text--darken-1 mt-2">
                                        Bilder hier ablegen
                                    </div>
                                    <div class="caption grey--text mt-1">
                                        oder klicken um auszuwählen
                                    </div>
                                </div>

                                <!-- Preview Area -->
                                <div v-if="packaging.images.length" class="preview-container mt-4">
                                    <v-chip
                                        v-for="(file, index) in packaging.images"
                                        :key="index"
                                        class="ma-1"
                                        close
                                        @click:close="removeImage(index)"
                                    >
                                        <v-avatar left>
                                            <v-img
                                                :src="getImagePreview(file)"
                                                width="20"
                                                height="20"
                                                contain
                                            ></v-img>
                                        </v-avatar>
                                        {{ file.name }}
                                    </v-chip>
                                </div>
                            </div>
                        </v-col>

                        <v-col cols="12">
                            <v-textarea
                                v-model="packaging.description"
                                label="Beschreibung"
                                outlined
                                dense
                                rows="3"
                                placeholder="Optionale Beschreibung der Kartonage"
                            ></v-textarea>
                            <v-text-field
                                v-model="packaging.stock"
                                label="Initialer Lagerbestand"
                                type="number"
                                outlined
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions class="pa-4">
                <v-spacer></v-spacer>
                <v-btn 
                    text 
                    @click="close"
                    class="mr-2"
                >
                    Abbrechen
                </v-btn>
                <v-btn 
                    color="primary"
                    @click="submit"
                    :loading="loading"
                >
                    <v-icon left>mdi-content-save</v-icon>
                    Speichern
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'NewPackagingDialog',
    data() {
        return {
            loading: false,
            packaging: {
                title: '',
                sku: '',
                lengthCm: null,
                widthCm: null,
                heightCm: null,
                weight: null,
                pricePerUnit: 0,
                description: '',
                images: [],
                stock: 0,
                isActive: true
            }
        }
    },
    props: {
        show: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        close() {
            this.$emit('close');
        },
        submit() {
            this.$emit('submit', this.packaging);
        },
        handleFileSelect(event) {
            // Will implement later
        },
        removeImage(index) {
            // Will implement later
        },
        getImagePreview(file) {
            // Will implement later
            return '';
        }
    }
}
</script>

<style scoped>
.dropzone-container {
    border: 2px dashed rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    position: relative;
    transition: all 0.3s ease;
    background: #fafafa;
    min-height: 200px;
    cursor: pointer;
}

.dropzone-container:hover {
    border-color: var(--v-primary-base);
    background: #f5f5f5;
}

.dropzone-content {
    padding: 40px;
    text-align: center;
}

.file-input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
}

.preview-container {
    padding: 8px;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    background: white;
    border-radius: 0 0 8px 8px;
}
</style>