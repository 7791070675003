<template>
    <div class="pa-4">
        <Header />

        <!-- Centered Search Bar -->
        <div class="d-flex justify-center mb-6">
            <div class="search-pill">
                <v-text-field
                    v-model="search"
                    placeholder="Suchen..."
                    prepend-inner-icon="mdi-magnify"
                    dense
                    hide-details
                    filled
                    rounded
                    background-color="grey lighten-4"
                    class="search-field"
                >
                    <template v-slot:prepend-inner>
                        <v-icon color="grey darken-2">mdi-magnify</v-icon>
                    </template>
                </v-text-field>
            </div>
        </div>

        <!-- Bulk Actions Bar -->
        <div class="d-flex align-center justify-end mb-4">
            <v-btn
                color="primary"
                elevation="1"
                rounded
                @click="newPackagingDialog = true"
                class="mr-2"
            >
                <v-icon left>mdi-plus</v-icon>
                Neue Kartonage
            </v-btn>

            <v-btn
                :disabled="!selected.length"
                color="secondary"
                text
                rounded
                @click="bulkEdit"
                class="mr-2"
            >
                <v-icon left>mdi-pencil</v-icon>
                Bearbeiten ({{ selected.length }})
            </v-btn>

            <v-btn
                :disabled="!selected.length"
                color="error"
                text
                rounded
                @click="bulkDelete"
            >
                <v-icon left>mdi-delete</v-icon>
                Löschen ({{ selected.length }})
            </v-btn>
        </div>

        <!-- Modern Data Table -->
        <v-card
            elevation="0"
            class="rounded-lg"
        >
            <v-data-table
                v-model="selected"
                :headers="headers"
                :items="packagings"
                :search="search"
                :loading="loading"
                :items-per-page="10"
                show-select
                item-key="_id"
                class="modern-table"
                :footer-props="{
                    'items-per-page-options': [10, 25, 50],
                    'items-per-page-text': 'Zeilen pro Seite',
                    'class': 'modern-footer'
                }"
            >
                <!-- SKU Column -->
                <template v-slot:item.sku="{ item }">
                    <span class="font-weight-medium grey--text text--darken-3">{{ item.sku }}</span>
                </template>

                <!-- Title Column -->
                <template v-slot:item.title="{ item }">
                    <div class="d-flex align-center">
                        <v-avatar 
                            size="32" 
                            rounded 
                            class="mr-2 grey lighten-4"
                        >
                            <v-img
                                v-if="item.images && item.images.length"
                                :src="item.images[0]"
                                contain
                            ></v-img>
                            <v-icon v-else color="grey darken-1">mdi-package-variant</v-icon>
                        </v-avatar>
                        <span class="grey--text text--darken-4">{{ item.title }}</span>
                    </div>
                </template>

                <!-- Dimensions Column -->
                <template v-slot:item.dimensions="{ item }">
                    <span class="mono-text">{{ item.lengthCm }} × {{ item.widthCm }} × {{ item.heightCm }}</span>
                    <span class="caption grey--text ml-1">cm</span>
                </template>

                <!-- Weight Column -->
                <template v-slot:item.weight="{ item }">
                    <span class="mono-text">{{ item.weight }}</span>
                    <span class="caption grey--text ml-1">g</span>
                </template>

                <!-- Stock Column -->
                <template v-slot:item.stock="{ item }">
                    <v-chip
                        x-small
                        :color="getStockColor(item.stock)"
                        class="font-weight-medium stock-chip"
                        label
                        @click.stop="openStockEdit(item)"
                    >
                        <v-icon x-small class="edit-icon-left">mdi-pencil</v-icon>
                        {{ item.stock }}
                        <span class="unit-label">stk</span>
                    </v-chip>
                </template>

                <!-- Price Column -->
                <template v-slot:item.pricePerUnit="{ item }">
                    <div class="price-chip" @click.stop="openPriceEdit(item)">
                        <span class="mono-text">{{ formatPrice(item.pricePerUnit) }}</span>
                        <span class="caption grey--text ml-1">€</span>
                        <v-icon x-small class="edit-icon-right">mdi-pencil</v-icon>
                    </div>
                </template>

                <!-- Actions Column -->
                <template v-slot:item.actions="{ item }">
                    <div class="actions-wrapper">
                        <v-btn
                            icon
                            x-small
                            class="mr-1"
                            @click.stop="openPackagingLogs(item)"
                        >
                            <v-icon small color="grey darken-1">mdi-eye</v-icon>
                        </v-btn>
                        <v-btn
                            icon
                            x-small
                            class="mr-1"
                            @click.stop="editPackaging(item)"
                        >
                            <v-icon small color="grey darken-1">mdi-pencil</v-icon>
                        </v-btn>
                        <v-btn
                            icon
                            x-small
                            @click.stop="deletePackaging(item)"
                        >
                            <v-icon small color="grey darken-1">mdi-delete</v-icon>
                        </v-btn>
                    </div>
                </template>

                <!-- No Data State -->
                <template v-slot:no-data>
                    <div class="pa-8 text-center">
                        <v-icon size="64" color="grey lighten-1">mdi-package-variant</v-icon>
                        <div class="text-subtitle-1 grey--text text--darken-1 mt-2">
                            Keine Kartonagen verfügbar
                        </div>
                    </div>
                </template>

                <!-- Loading State -->
                <template v-slot:loading>
                    <div class="pa-8 text-center">
                        <v-progress-circular
                            indeterminate
                            color="primary"
                        ></v-progress-circular>
                    </div>
                </template>
            </v-data-table>
        </v-card>

        <NewPackagingDialog
            :show.sync="newPackagingDialog"
            @close="newPackagingDialog = false"
            @submit="createPackaging"
        />

        <StockEditDialog
            :show="stockDialog"
            :item="editingItem"
            @close="cancelStockEdit"
            @submit="handleStockUpdate"
        />

        <LogsDialog
            v-if="editingItem"
            :show="logsDialog"
            :sku="editingItem.sku"
            :logs="logs"
            @close="logsDialog = false"
        />

        <EditPackagingDialog
            v-if="editingItem"
            :show="editPackagingDialog"
            :item="editingItem"
            @close="editPackagingDialog = false"
            @submit="handlePackagingUpdate"
        />

        <PriceEditDialog
            v-if="editingItem"
            :show="priceDialog"
            :item="editingItem"
            @close="cancelPriceEdit"
            @submit="handlePriceUpdate"
        />

        <BulkStockEditDialog
            :show="bulkStockDialog"
            :selected-items="selected"
            @close="cancelBulkStockEdit"
            @submit="handleBulkStockUpdate"
        />

        <v-snackbar
            v-model="snackbar.show"
            :timeout="snackbar.timeout"
            :color="snackbar.color"
            :right="true"
            :top="true"
            elevation="4"
            rounded="pill"
            class="custom-snackbar"
        >
            <div class="d-flex align-center">
                <v-icon 
                    left 
                    class="mr-2"
                    v-if="snackbar.color === 'success'"
                >
                    mdi-check-circle
                </v-icon>
                <v-icon 
                    left 
                    class="mr-2"
                    v-else-if="snackbar.color === 'error'"
                >
                    mdi-alert-circle
                </v-icon>
                {{ snackbar.message }}
                <v-btn
                    icon
                    x-small
                    class="ml-4"
                    @click="snackbar.show = false"
                >
                    <v-icon small>mdi-close</v-icon>
                </v-btn>
            </div>
        </v-snackbar>
    </div>
</template>

<script>
import PackagingService from '@/services/PackagingService';
import Header from '../header.vue';
import NewPackagingDialog from './NewPackagingDialog.vue';
import StockEditDialog from './StockEditDialog.vue';
import LogsDialog from './LogsDialog.vue';
import PriceEditDialog from './PriceEditDialog.vue';
import BulkStockEditDialog from './BulkStockEditDialog.vue';
import EditPackagingDialog from './EditPackagingDialog.vue';

export default {
    name: 'PackagingRoot',
    components: {
        Header,
        NewPackagingDialog,
        StockEditDialog,
        LogsDialog,
        PriceEditDialog,
        BulkStockEditDialog,
        EditPackagingDialog
    },
    data() {
        return {
            packagings: [],
            newPackagingDialog: false,
            search: '',
            loading: false,
            headers: [
                { 
                    text: 'SKU',
                    align: 'start',
                    value: 'sku',
                    width: '120'
                },
                { 
                    text: 'Bezeichnung',
                    value: 'title'
                },
                { 
                    text: 'Maße',
                    value: 'dimensions',
                    width: '200'
                },
                { 
                    text: 'Gewicht',
                    value: 'weight',
                    width: '150'
                },
                { 
                    text: 'Bestand',
                    value: 'stock',
                    width: '150'
                },
                { 
                    text: 'Preis',
                    value: 'pricePerUnit',
                    width: '100'
                },
                { 
                    text: 'Aktionen',
                    value: 'actions',
                    sortable: false,
                    width: '100'
                }
            ],
            selected: [],
            snackbar: {
                show: false,
                message: '',
                color: 'success',
                timeout: 3000
            },
            stockDialog: false,
            editingItem: null,
            editPackagingDialog: false,
            logsDialog: false,
            logs: [],
            priceDialog: false,
            bulkStockDialog: false,
        }
    },
    methods: {
        async getPackagings() {
            this.loading = true;
            try {
                const response = await PackagingService.getPackagings();
                this.packagings = response.data;
            } catch (error) {
                console.error('Error fetching packagings:', error);
            } finally {
                this.loading = false;
            }
        },
        async createPackaging(data) {
            try {
                await PackagingService.createPackaging(data);
                await this.getPackagings();
                this.newPackagingDialog = false;
            } catch (error) {
                console.error('Error creating packaging:', error);
                this.showSnackbar(error.response.data, 'error');
            }
        },
        async getLogs(sku) {
            try {
                const response = await PackagingService.getPackagingLogs(sku);
                this.logs = response.data;
            } catch (error) {
                console.error('Error fetching logs:', error);
            }
        },
        getStockColor(stock) {
            if (stock <= 10) return 'error';
            if (stock <= 200) return 'warning';
            return 'success';
        },
        formatPrice(price) {
            return Number(price).toFixed(2);
        },
        editPackaging(item) {
            this.editingItem = item;
            this.editPackagingDialog = true;
        },
        async deletePackaging(item) {
            try {
                let response = await PackagingService.deletePackaging(item._id);
                this.getPackagings();
                this.showSnackbar(response.data.message, 'success');
            } catch (error) {
                console.error('Error deleting packaging:', error);
                this.showSnackbar(error.response.data, 'error');
            }
        },
        bulkEdit() {
            this.bulkStockDialog = true;
        },
        async bulkDelete() {
            try {
                for (let item of this.selected) {
                    await this.deletePackaging(item);
                }
                this.showSnackbar('Kartonagen erfolgreich gelöscht', 'success');
                this.selected = [];
            } catch (error) {
                console.error('Error deleting packaging:', error);
                this.showSnackbar(error.response.data, 'error');
            }
        },
        showSnackbar(message, color) {
            this.snackbar.show = true;
            this.snackbar.message = message;
            this.snackbar.color = color;
        },
        openStockEdit(item) {
            this.editingItem = item;
            this.stockDialog = true;
        },
        cancelStockEdit() {
            this.stockDialog = false;
            this.editingItem = null;
        },
        async handleStockUpdate({ sku, stock }) {
            try {
                let response = await PackagingService.updatePackagingStock(sku, { stock });
                await this.getPackagings();
                this.showSnackbar(response.data.message, 'success');
                this.cancelStockEdit();
            } catch (error) {
                console.error('Error updating stock:', error);
                this.showSnackbar(error.response?.data || 'Fehler beim Aktualisieren des Bestands', 'error');
            }
        },
        async openPackagingLogs(item) {
            this.editingItem = item;
            await this.getLogs(item.sku);
            this.logsDialog = true;
        },
        openPriceEdit(item) {
            this.editingItem = item;
            this.priceDialog = true;
        },
        cancelPriceEdit() {
            this.priceDialog = false;
            this.editingItem = null;
        },
        async handlePriceUpdate(item) {
            try {
                let response = await PackagingService.updatePackaging(item._id, item);
                await this.getPackagings();
                this.showSnackbar(response.data.message, 'success');
                this.cancelPriceEdit();
            } catch (error) {
                console.error('Error updating price:', error);
                this.showSnackbar(error.response?.data || 'Fehler beim Aktualisieren des Preises', 'error');
            }
        },
        cancelBulkStockEdit() {
            this.bulkStockDialog = false;
            this.selected = [];
        },
        async handleBulkStockUpdate(updates) {
            try {
                for (let update of updates) {
                    await PackagingService.updatePackagingStock(update.sku, { stock: update.stock });
                }
                
                this.showSnackbar('Bestände erfolgreich aktualisiert', 'success');
                this.cancelBulkStockEdit();
                await this.getPackagings();
            } catch (error) {
                console.error('Error updating stocks:', error);
                this.showSnackbar(error.response?.data || 'Fehler beim Aktualisieren der Bestände', 'error');
            }
        },
        async handlePackagingUpdate(updatedItem) {
            try {
                let response = await PackagingService.updatePackaging(updatedItem._id, updatedItem);
                await this.getPackagings();
                this.showSnackbar(response.data.message, 'success');
            } catch (error) {
                console.error('Error updating packaging:', error);
                this.showSnackbar(error.response?.data || 'Fehler beim Aktualisieren der Kartonage', 'error');
            }
        }
    },
    created() {
        this.getPackagings();
    }
}
</script>

<style scoped>
.search-pill {
    width: 50%;
}

.search-pill ::v-deep .v-input__control {
    min-height: 44px;
}

.search-pill ::v-deep .v-text-field__details {
    display: none;
}

.search-pill ::v-deep .v-input__slot {
    box-shadow: 0 1px 2px rgba(0,0,0,0.1) !important;
    transition: box-shadow 0.2s ease;
}

.search-pill ::v-deep .v-input__slot:hover {
    box-shadow: 0 2px 4px rgba(0,0,0,0.15) !important;
}

.modern-table {
    border: 1px solid rgba(0,0,0,0.08);
    border-radius: 8px;
}

.modern-table ::v-deep .v-data-table__wrapper {
    border-radius: 8px;
}

.modern-table ::v-deep thead th {
    font-size: 0.825rem !important;
    text-transform: uppercase !important;
    letter-spacing: 0.5px !important;
    color: rgba(0,0,0,0.6) !important;
    background: #f8f9fa !important;
}

.modern-table ::v-deep tbody tr {
    cursor: pointer;
    transition: background-color 0.2s ease;
}

.modern-table ::v-deep tbody tr:hover {
    background-color: #f8f9fa !important;
}

.modern-table ::v-deep .v-data-footer {
    border-top: 1px solid rgba(0,0,0,0.08);
    background: #ffffff;
}

.mono-text {
    font-family: 'Roboto Mono', monospace;
    font-size: 0.875rem;
}

.actions-wrapper {
    opacity: 0.6;
    transition: opacity 0.2s ease;
}

.modern-table ::v-deep tbody tr:hover .actions-wrapper {
    opacity: 1;
}

.v-btn.v-btn--disabled {
    opacity: 0.6;
}

.custom-snackbar ::v-deep .v-snack__wrapper {
    min-width: auto !important;
    max-width: 400px !important;
    padding: 0 20px !important;
}

.custom-snackbar ::v-deep .v-snack__content {
    padding: 12px 0;
}

.stock-chip {
    cursor: pointer;
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    min-width: 60px;
}

.stock-chip:hover {
    transform: translateY(-1px);
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.edit-icon-left {
    margin-right: 4px;
    opacity: 0;
    transform: translateX(5px);
    transition: all 0.3s ease;
}

.stock-chip:hover .edit-icon-left {
    opacity: 1;
    transform: translateX(0);
}

.unit-label {
    font-size: 0.65rem;
    opacity: 0.8;
    margin-left: 2px;
}

.stock-status-panel {
    display: flex;
    align-items: center;
    padding: 12px;
    background: rgba(0,0,0,0.03);
    border-radius: 4px;
}

.stock-indicator {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 12px;
}

.stock-indicator.critical {
    background-color: #ff5252;
}

.stock-indicator.warning {
    background-color: #ffa726;
}

.stock-indicator.good {
    background-color: #66bb6a;
}

.stock-indicator.unknown {
    background-color: #bdbdbd;
}

.stock-status {
    color: rgba(0,0,0,0.6);
    font-weight: 500;
    font-size: 0.875rem;
}

.current-stock-label {
    font-size: 0.75rem;
    margin-bottom: 4px;
}

.price-chip {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    padding: 4px 8px;
    border-radius: 4px;
    transition: all 0.3s ease;
}

.price-chip:hover {
    background: rgba(0,0,0,0.05);
}

.edit-icon-right {
    margin-left: 4px;
    opacity: 0;
    transform: translateX(-5px);
    transition: all 0.3s ease;
}

.price-chip:hover .edit-icon-right {
    opacity: 1;
    transform: translateX(0);
}
</style>