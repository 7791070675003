<template>
    <v-dialog
        :value="show"
        max-width="400"
        @click:outside="$emit('close')"
    >
        <v-card>
            <v-card-title class="headline pb-2">
                Bestand bearbeiten
                <v-chip
                    small
                    label
                    class="ml-2"
                    color="grey lighten-3"
                >
                    {{ item?.sku }}
                </v-chip>
            </v-card-title>

            <v-card-subtitle class="pt-2">
                {{ item?.title }}
            </v-card-subtitle>

            <v-card-text>
                <!-- Edit Mode Selector -->
                <v-btn-toggle
                    v-model="editMode"
                    mandatory
                    class="mb-4"
                    dense
                >
                    <v-btn value="absolute" small>
                        <v-icon small left>mdi-equal</v-icon>
                        Neuer Wert
                    </v-btn>
                    <v-btn value="relative" small>
                        <v-icon small left>mdi-plus-minus</v-icon>
                        Änderung
                    </v-btn>
                </v-btn-toggle>

                <v-row align="center" class="mx-0">
                    <v-col :cols="editMode === 'absolute' ? 8 : 4">
                        <v-text-field
                            v-if="editMode === 'absolute'"
                            v-model="stockValue"
                            type="number"
                            label="Neuer Bestand"
                            :color="getStockColor(computedNewStock)"
                            dense
                            outlined
                            autofocus
                            hide-details
                            @keyup.enter="handleSubmit"
                            @keyup.esc="$emit('close')"
                        ></v-text-field>
                        <v-text-field
                            v-else
                            v-model="changeValue"
                            type="number"
                            :label="changeLabel"
                            :color="changeValue > 0 ? 'success' : 'error'"
                            dense
                            outlined
                            autofocus
                            hide-details
                            @keyup.enter="handleSubmit"
                            @keyup.esc="$emit('close')"
                        >
                            <template v-slot:prepend>
                                <v-btn-toggle
                                    v-model="changeType"
                                    mandatory
                                    dense
                                    class="mt-n2"
                                >
                                    <v-btn
                                        x-small
                                        value="increase"
                                        :color="changeType === 'increase' ? 'success' : ''"
                                    >
                                        <v-icon x-small>mdi-plus</v-icon>
                                    </v-btn>
                                    <v-btn
                                        x-small
                                        value="decrease"
                                        :color="changeType === 'decrease' ? 'error' : ''"
                                    >
                                        <v-icon x-small>mdi-minus</v-icon>
                                    </v-btn>
                                </v-btn-toggle>
                            </template>
                        </v-text-field>
                    </v-col>
                    <v-col :cols="editMode === 'absolute' ? 4 : 4" class="text-center grey--text">
                        <div class="current-stock-label">Aktuell</div>
                        <v-chip
                            x-small
                            :color="getStockColor(item?.stock)"
                            dark
                        >
                            {{ item?.stock }}
                        </v-chip>
                    </v-col>
                    <v-col v-if="editMode === 'relative'" cols="4" class="text-center grey--text">
                        <div class="current-stock-label">Neu</div>
                        <v-chip x-small :color="getStockColor(computedNewStock)" dark>
                            {{ computedNewStock }}
                        </v-chip>
                    </v-col>
                </v-row>

                <div class="stock-status-panel mt-4">
                    <div 
                        class="stock-indicator"
                        :class="getStockIndicatorClass(computedNewStock)"
                    ></div>
                    <div class="stock-status">
                        {{ getStockStatus(computedNewStock) }}
                    </div>
                </div>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    text
                    @click="$emit('close')"
                >
                    Abbrechen
                </v-btn>
                <v-btn
                    color="primary"
                    :disabled="!isValidStock"
                    @click="handleSubmit"
                >
                    Speichern
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'StockEditDialog',
    
    props: {
        show: {
            type: Boolean,
            default: false
        },
        item: {
            type: Object,
            default: null
        }
    },

    data() {
        return {
            editMode: 'absolute',
            stockValue: null,
            changeValue: null,
            changeType: 'increase'
        }
    },

    computed: {
        changeLabel() {
            return this.changeType === 'increase' ? 'Erhöhen um' : 'Reduzieren um';
        },

        computedNewStock() {
            if (this.editMode === 'absolute') {
                return parseInt(this.stockValue) || 0;
            } else {
                const change = parseInt(this.changeValue) || 0;
                const baseStock = this.item?.stock || 0;
                return this.changeType === 'increase' 
                    ? baseStock + change 
                    : baseStock - change;
            }
        },

        isValidStock() {
            if (this.editMode === 'absolute') {
                const stock = parseInt(this.stockValue);
                return !isNaN(stock) && stock >= 0;
            } else {
                const change = parseInt(this.changeValue);
                return !isNaN(change) && change >= 0 && this.computedNewStock >= 0;
            }
        }
    },

    watch: {
        show(newVal) {
            if (newVal && this.item) {
                this.stockValue = this.item.stock;
                this.changeValue = null;
                this.editMode = 'absolute';
                this.changeType = 'increase';
            }
        }
    },

    methods: {
        getStockColor(stock) {
            if (stock <= 10) return 'error';
            if (stock <= 50) return 'warning';
            return 'success';
        },

        getStockIndicatorClass(stock) {
            const level = parseInt(stock);
            if (isNaN(level)) return 'unknown';
            if (level <= 10) return 'critical';
            if (level <= 50) return 'warning';
            return 'good';
        },

        getStockStatus(stock) {
            const level = parseInt(stock);
            if (isNaN(level)) return 'Ungültiger Wert';
            if (level <= 0) return 'Nicht auf Lager';
            if (level <= 10) return 'Kritischer Bestand';
            if (level <= 50) return 'Niedriger Bestand';
            return 'Ausreichender Bestand';
        },

        handleSubmit() {
            if (!this.isValidStock) return;
            
            const newStock = this.computedNewStock;
            const change = this.editMode === 'relative' ? {
                type: this.changeType === 'increase' ? 'STOCK_INCREASE' : 'STOCK_DECREASE',
                amount: parseInt(this.changeValue)
            } : null;

            this.$emit('submit', {
                sku: this.item.sku,
                stock: newStock,
                change
            });
        }
    }
}
</script>

<style scoped>
.stock-status-panel {
    display: flex;
    align-items: center;
    padding: 12px;
    background: rgba(0,0,0,0.03);
    border-radius: 4px;
}

.stock-indicator {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 12px;
}

.stock-indicator.critical {
    background-color: #ff5252;
}

.stock-indicator.warning {
    background-color: #ffa726;
}

.stock-indicator.good {
    background-color: #66bb6a;
}

.stock-indicator.unknown {
    background-color: #bdbdbd;
}

.stock-status {
    color: rgba(0,0,0,0.6);
    font-weight: 500;
    font-size: 0.875rem;
}

.current-stock-label {
    font-size: 0.75rem;
    margin-bottom: 4px;
}

.v-btn-toggle {
    background: white;
}

.v-text-field ::v-deep .v-input__prepend-outer {
    margin-top: 0;
    margin-right: 0;
}
</style> 