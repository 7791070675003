<template>
    <v-dialog
        :value="show"
        max-width="400"
        @click:outside="$emit('close')"
    >
        <v-card>
            <v-card-title class="headline pb-2">
                Preis bearbeiten
                <v-chip
                    small
                    label
                    class="ml-2"
                    color="grey lighten-3"
                >
                    {{ item?.sku }}
                </v-chip>
            </v-card-title>

            <v-card-subtitle class="pt-2">
                {{ item?.title }}
            </v-card-subtitle>

            <v-card-text>
                <v-row align="center" class="mx-0">
                    <v-col cols="8">
                        <v-text-field
                            v-model="priceValue"
                            type="number"
                            step="0.01"
                            label="Neuer Preis"
                            prefix="€"
                            dense
                            outlined
                            autofocus
                            hide-details
                            @keyup.enter="handleSubmit"
                            @keyup.esc="$emit('close')"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="4" class="text-center grey--text">
                        <div class="current-price-label">Aktuell</div>
                        <v-chip
                            x-small
                            color="primary"
                            dark
                        >
                            {{ formatPrice(item?.pricePerUnit) }} €
                        </v-chip>
                    </v-col>
                </v-row>

                <div class="price-change-panel mt-4" v-if="showPriceChange">
                    <div class="price-change" :class="getPriceChangeClass">
                        <v-icon small left>{{ getPriceChangeIcon }}</v-icon>
                        {{ getPriceChangeText }}
                    </div>
                </div>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    text
                    @click="$emit('close')"
                >
                    Abbrechen
                </v-btn>
                <v-btn
                    color="primary"
                    :disabled="!isValidPrice"
                    @click="handleSubmit"
                >
                    Speichern
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'PriceEditDialog',
    
    props: {
        show: {
            type: Boolean,
            default: false
        },
        item: {
            type: Object,
            default: null
        }
    },

    data() {
        return {
            priceValue: null
        }
    },

    watch: {
        show(newVal) {
            if (newVal && this.item) {
                this.priceValue = this.item.pricePerUnit
            }
        }
    },

    computed: {
        isValidPrice() {
            const price = parseFloat(this.priceValue);
            return !isNaN(price) && price >= 0;
        },

        showPriceChange() {
            return this.isValidPrice && this.priceValue !== this.item?.pricePerUnit;
        },

        priceChange() {
            if (!this.isValidPrice || !this.item?.pricePerUnit) return 0;
            return parseFloat(this.priceValue) - this.item.pricePerUnit;
        },

        getPriceChangeClass() {
            if (!this.item?.pricePerUnit) return {};
            return {
                'success--text': this.priceChange > 0,
                'error--text': this.priceChange < 0
            }
        },

        getPriceChangeIcon() {
            if (!this.item?.pricePerUnit) return 'mdi-minus';
            if (this.priceChange > 0) return 'mdi-arrow-up';
            if (this.priceChange < 0) return 'mdi-arrow-down';
            return 'mdi-minus';
        },

        getPriceChangeText() {
            if (!this.item?.pricePerUnit) return '';
            const change = Math.abs(this.priceChange).toFixed(2);
            const percentage = (Math.abs(this.priceChange) / this.item.pricePerUnit * 100).toFixed(1);
            return `${change} € (${percentage}%)`;
        }
    },

    methods: {
        formatPrice(price) {
            return Number(price).toFixed(2);
        },

        handleSubmit() {
            if (!this.isValidPrice) return;
            this.$emit('submit', {
                ...this.item,
                pricePerUnit: parseFloat(this.priceValue)
            });
        }
    }
}
</script>

<style scoped>
.current-price-label {
    font-size: 0.75rem;
    margin-bottom: 4px;
}

.price-change-panel {
    display: flex;
    align-items: center;
    padding: 12px;
    background: rgba(0,0,0,0.03);
    border-radius: 4px;
}

.price-change {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 0.875rem;
}

.v-text-field ::v-deep input {
    font-family: 'Roboto Mono', monospace;
}
</style> 